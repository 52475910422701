function Footer() {
  return (
    <div style={{ backgroundcolor: 'black', color: 'black', padding: '20px' }}>
      <div class="page-footer font-small blue">
        <footer class="w-100 mb-2 start-50 footer-copyright py-3 text-center">
          © 2024 Copyright: Author: OpenSpace Team
          <a href="/"> http://hub.openspaceproject.com/</a>
        </footer>
      </div>
    </div>
  );
}
export default Footer;
